import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { WindowService } from './view-project/services/window.service';
import packageJson from '../../package.json';

/** variable tag */
declare var gtag;
/** Componente app */
@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public version: string = packageJson.version;

  /**
   * Constructor
   * @param router
   */
  constructor(
    private router: Router,
    private windowService: WindowService
  ) { }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    if (this.windowService.isBrowser) {
      console.log('eyesNroad v.' + this.version);
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', 'G-7QMBW7SB6T', {
            page_path: event.urlAfterRedirects
          });
        }
      });
    }
  }
}
